import React from "react";
import PropTypes from 'prop-types';
import platform from '../../../assets/images/platform.png'
import security from '../../../assets/images/security.png'
import fee from '../../../assets/images/fee.png'


const Overview = ({ homepagedata }) => {


    return (
        <div className="OvrVwSec">
            <div className="container">
                <div className="ovrVwTp">
                    <h3>{homepagedata?.tittletwo}</h3>
                    <p
                        dangerouslySetInnerHTML={{ __html: homepagedata?.tittletwosubtitle }}
                    />
                </div>

                <div className="row align-items-end OvrVwDts">
                    <div className="col-lg-4">
                        <div className="OvrVwDtsCnt">
                            <div className="OvrVwDtsCntTp">
                                <h4>{homepagedata?.cardonetitle}</h4>
                                <img src={homepagedata?.image1 ? homepagedata?.image1 : fee} className="d-block mx-lg-auto" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="OvrVwDtsCnt">
                            <div className="OvrVwDtsCntTp">
                                <h4>{homepagedata?.cardtwotitle}</h4>
                                <img src={homepagedata?.image2 ? homepagedata?.image2 : security} className="d-block mx-lg-auto" alt="" />
                            </div>
                            <p>{homepagedata?.cardtwodescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="OvrVwDtsCnt">
                            <div className="OvrVwDtsCntTp flex-wrap">
                                <img src={platform} className="d-block mx-lg-auto" alt="" />
                                <h4
                                    dangerouslySetInnerHTML={{ __html: homepagedata?.cardthreetitle }}
                                />
                            </div>
                            <p>{homepagedata?.cardthreedescription}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
Overview.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Overview;