import "./scss/myprofile.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useEffect, useState } from "react";

import edit from "../../assets/images/edit.png";
import leftarrowImg from "../../assets/images/leftarrow.png";

import { CONST } from "../../config";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
import profile1 from '../../assets/images/profile.png'
import Helper from "../../common/Helper";
import { CountryFile } from "../../common/Country"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PropTypes from "prop-types";



const Myprofile = (props) => {
  toast.configure()
  const navigate = useNavigate()
  const [profile, setProfile] = useState();
  const [status, setStatus] = useState(false);
  const [editData, setEditData] = useState(false);
  const [profileimg, setProfileimg] = useState(null)
  const [proStat, setProStat] = useState(false);
  const [loading, setLoading] = useState(true);
  let color = "#ffffff";
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [maxDate, setMaxDate] = useState(new Date())
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "onChange" });

  useEffect(() => {
    const token = localStorage.getItem('loginId');
    if (token) {
      fetchData()
      getDate();
    }
  }, [proStat])

  const fetchData = async () => {
    setLoading(true);
    let getData = await Helper?.PostData(`${CONST.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
    if (getData?.status === true) {
      setProfile(getData?.user);

      setProfileimg(getData?.user?.profile_pic)
      setStatus(true)
    }
    setLoading(false)
  }
  const editProfile = (e) => {
    e.preventDefault()
    setEditData(true)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    if (data.fname !== '' || data.lname !== '' || data.dob !== '' || data.phnno !== '' || data.city !== '' || data.profileimg !== '' || data.email !== '') {
      let data1 = {
        'firstname': data.fname ? data.fname : profile.first_name,
        'lastname': data.lname ? data.lname : profile.last_name,
        'dob': data.dob ? data.dob : profile.dob,
        'phone': data.phnno ? data.phnno : profile.phone,
        'city': data.city ? data.city : profile.city,
        'profile_pic': profileimg ? profileimg : profile.profile_picture,
        'email': profile.email,
        'country': selectedCountry
      }
      let updateProfile = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/EklJCpk0AWqQ0YBzTS7T`, data1);
      if (updateProfile?.status === true) {
        props.setProfilePic(profileimg ? profileimg : profile.profile_picture)
        toast.success(updateProfile?.msg)
        await fetchData();
        setProStat(true)
        setLoading(false)
        setEditData(false)
      }
    }
    else {
      toast.warning('Please Enter All Fields')
    }

  }


  const profileImg = async (e) => {
    setLoading(true);
    e.preventDefault();
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2 MB
    if (file.size > maxSize) {
      toast.error('File size exceeds 2 MB limit.');
      setLoading(false);
      setProfileimg(null);
      e.target.value = ''
      return;
    }

    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.jfif)$/i;
    let n = (file.type).includes("image");

    if (!allowedExtensions.exec(file.name) || !n) {
      toast.error('Please upload file having extensions .jpeg/.jpg/.png/.jfif only.');
      setProfileimg('')
      e.target.value = ''
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("myImage", file);
      let getProfile = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/l1yGhE066s5WCQQGd57t`, formData);

      if (getProfile?.status === true) {
        setProfileimg(getProfile?.result?.secure_url);
        props.setProfilePic(getProfile?.result?.secure_url)
        setLoading(false)
      } else {
        setProfileimg(null);
        e.target.value = ''
      }
    } catch (error) {
      toast.error('An error occurred during upload.');
      setProfileimg(null);
      e.target.value = '';
    } finally {
      setLoading(false);
    }
  };
  let minDateSelect = '';
  const getDate = () => {
    const currentYear = new Date().getFullYear();
    const pastYear = currentYear - 18;
    let currentDate = new Date().getDate()
    if (currentDate < 10) {
      currentDate = "0" + currentDate
    } else {
      currentDate = new Date().getDate()
    }
    let currentMonth = new Date().getMonth()

    if (currentMonth < 10) {
      currentMonth = "0" + currentMonth
    } else {
      currentMonth = new Date().getMonth()
    }
    minDateSelect = currentDate + "-" + currentMonth + "-" + pastYear
    setMaxDate(minDateSelect)
  }

  const Back = () => {
    navigate('/wallet')
  }

  const handleChangeCountry = (value, countryObj) => {
    setValue("phnno", value);
    setValue("dialCode", countryObj.dialCode);

    let countryCode = (countryObj.countryCode).toUpperCase();
    let temp = CountryFile.find((item) => item.code === countryCode)
    setSelectedCountry(temp.name);
  }

  return (
    <Fragment>

      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      {status === true && editData === true ? <>
        <Header profilePic={props.profilePic} />
        <div className="cntld">
          <div className="w-100 walletContainer">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="DepositWithdrawCnt myProfile">
                    <div className="head d-flex align-items-center">
                      <img
                        src={leftarrowImg}
                        className="me-2 pointer"
                        onClick={Back}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Space') {
                            Back();
                          }
                        }}
                        alt=""
                      />
                      <h3 className="mb-0">My Profile</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-12 d-flex flex-wrap align-items-center profileRow mb-4">
                              <div>
                                <img src={profile.profile_pic == '' ? profile1 : profile.profile_pic} className="profilePic me-3" alt="" />
                              </div>
                              <div className="d-flex flex-column">
                                <h3>{profile.firstname} {profile.lastname}</h3>
                                <p>@{profile.username}</p>
                              </div>
                              <button className="btn blueBtn ms-auto" onClick={editProfile}><img src={edit} className="me-2" alt="" /><span className="edit-pro">Edit Profile</span></button>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="exampleIFirstName">First Name<span className="mandatory">*</span></label>
                              <input
                                type="text"
                                className="form-control user"
                                placeholder="First Name"
                                defaultValue={profile.firstname}
                                {...register('fname', {
                                  required: true,
                                  pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/
                                })}
                                minLength="1"
                                maxLength="20" />
                              {errors.fname && errors.fname.type === 'required' && <span className='errors'>Please Enter the First Name</span>}
                              {errors.fname && errors.fname.type === 'pattern' && <span className='errors'>Please Enter Valid First Name</span>}
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="exampleLastName">Last Name<span className="mandatory">*</span></label>
                              <input
                                type="text"
                                className="form-control user"
                                placeholder="Last Name"
                                defaultValue={profile.lastname}
                                {...register('lname', {
                                  required: true,
                                  pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/
                                })}
                                minLength="1"
                                maxLength="20"
                              />
                              {errors.lname && errors.lname.type === 'required' && <span className='errors'>Please Enter the Last Name</span>}
                              {errors.lname && errors.lname.type === 'pattern' && <span className='errors'>Please Enter Valid Last Name</span>}
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="exampleInputEmail1">Email<span className="mandatory">*</span></label>
                              <input
                                type="text"
                                className="form-control mail"
                                placeholder="Email"
                                defaultValue={profile.email}
                                disabled
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="examplePhoneNumber">Phone Number<span className="mandatory">*</span></label>
                              <PhoneInput
                                country="in"
                                countryCodeEditable={false}
                                required={true}
                                onChange={(value, countryObj) => {
                                  handleChangeCountry(value, countryObj);
                                }}
                                value={profile.phone}
                                ref={register("phnno", {
                                  required: 'Phone number is required',
                                })}
                              />
                              {errors.phnno && (
                                <span className="errors">
                                  {errors.phnno.type === 'required'
                                    ? 'Please enter the mobile number'
                                    : errors.phnno.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="exampleDate">Date Of Birth<span className="mandatory">*</span></label>
                              <input
                                type="date"
                                className="form-control calendar" max={maxDate}
                                placeholder="Date" defaultValue={profile.dob}
                                {...register('dob', { required: 'true' })}
                              />
                              {errors.dob && errors.dob.type === 'required' && <span className="errors">Please Enter the DOB</span>}
                            </div>
                            <div className="col-lg-6 mb-3 city">
                              <label htmlFor="exampleCity" className="form-label">City<span className="mandatory">*</span></label>
                              <input type="text"
                                className="form-control user"
                                placeholder="City"
                                defaultValue={profile.city}
                                {...register('city', {
                                  required: true,
                                  pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/
                                })}
                                minLength="1"
                                maxLength="15"
                              />
                              {errors.city && errors.city.type === 'required' && <span className="errors">Please Enter your City</span>}
                              {errors.city && errors.city.type === 'pattern' && <span className="errors">Please Enter Valid City Name</span>}
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label" htmlFor="exampleProfilePic">Profile Picture<span className="mandatory">*</span></label>
                              <input
                                type="file"
                                className="form-control user"
                                placeholder="Profile Picture"
                                onChange={profileImg}
                              />
                            </div>
                            <div className="col-lg-6 mb-3 city">
                              <label htmlFor="exampleInputEmail1" className="form-label">Country<span className="mandatory">*</span></label>
                              <input type="text"
                                className="form-control"
                                value={selectedCountry}
                                readOnly
                                placeholder="Country"
                              />
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-lg-5 my-3">
                              <button className="btn orangeBtn">Update</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </> :
        status === true && editData === false ?
          <>
            <Header />
            <div className="cntld">
              <div className="w-100 walletContainer">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="DepositWithdrawCnt myProfile">
                        <div className="head d-flex align-items-center">
                          <img
                            src={leftarrowImg}
                            className="me-2 pointer"
                            onClick={Back}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'Space') {
                                Back();
                              }
                            }}
                            alt=""
                          />
                          <h3 className="mb-0">My Profile</h3>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <form>
                              <div className="row">
                                <div className="col-12 d-flex flex-wrap align-items-center profileRow mb-4">
                                  <div>
                                    <img src={profile.profile_pic == '' ? profile1 : profile.profile_pic} className="profilePic me-3" alt="" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <h3>{profile.firstname} {profile.lastname}</h3>
                                    <p>@{profile.username}</p>
                                  </div>
                                  <button className="btn blueBtn ms-auto" onClick={editProfile}><img src={edit} className="me-2" alt="" /><span className="edit-pro">Edit Profile</span></button>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="exampleFirstName">First Name<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control user" placeholder="First Name" defaultValue={profile.firstname} disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="exampleLastName">Last Name<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control user" placeholder="Last Name" defaultValue={profile.lastname} disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="exampleInputEmail1">Email<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control mail" placeholder="Email" defaultValue={profile.email} disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="examplePhoneNumber">Phone Number<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control phone" placeholder="Phone Number" defaultValue={profile.phone} disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="exampleDate">Date Of Birth<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control calendar" placeholder="Date" defaultValue={profile.dob} disabled />
                                </div>
                                <div className="col-lg-6 mb-3 city">
                                  <label htmlFor="exampleCity" className="form-label">City<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control user" placeholder="City" defaultValue={profile.city} disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label" htmlFor="exampleProfilePic">Profile Picture<span className="mandatory">*</span></label>
                                  <input
                                    type="file"
                                    className="form-control user"
                                    placeholder="Profile Picture"
                                    onChange={profileImg}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-6 mb-3 city">
                                  <label htmlFor="exampleCountry" className="form-label">Country<span className="mandatory">*</span></label>
                                  <input type="text" className="form-control user" placeholder="Country" defaultValue={profile.country} disabled />
                                </div>
                              </div>
                              <div className="row">
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </> :
          <></>}

    </Fragment>
  );

}
Myprofile.propTypes = {
  profilePic: PropTypes.any.isRequired, 
  setProfilePic: PropTypes.any.isRequired,};

export default Myprofile;