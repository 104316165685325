import React from "react";
import PropTypes from 'prop-types';
import ic1 from '../../../assets/images/ic1.png'
import ic2 from '../../../assets/images/ic2.png'
import ic3 from '../../../assets/images/ic3.png'
import ic4 from '../../../assets/images/ic4.png'
import ic5 from '../../../assets/images/ic5.png'
import rnd1 from '../../../assets/images/rnd1.png'
import rnd2 from '../../../assets/images/rnd2.png'
import rnd3 from '../../../assets/images/rnd3.png'
import rnd4 from '../../../assets/images/rnd4.png'
import rnd5 from '../../../assets/images/rnd5.png'
import rnd6 from '../../../assets/images/rnd6.png'
import tkssec from '../../../assets/images/tkssec.png'

const Tokenomics = ({ homepagedata, count }) => {

    const colorImg = [rnd1, rnd2, rnd3, rnd4, rnd5, rnd6]
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };


    return (
        <div className="TkmicSec">
            <div className="container">
                <div className="HdTkms text-center">
                    <h3>{homepagedata?.tittlethreesubtitle}</h3>
                    <p>{homepagedata?.tittlethree}</p>
                </div>
            </div>
            <div className="TkmicSecinner">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-4">
                            <div className="bg_tnks_2">
                                <img src={tkssec} alt="" className="img-fluid dimtnk" />
                                <div>
                                    <div className="ethmtsj">
                                        <div>
                                            <img src={ic1} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <h2>{homepagedata?.tokennetwork}</h2>
                                            <h3>Token Network</h3>
                                        </div>
                                    </div>
                                    <div className="ethmtsj">
                                        <div>
                                            <img src={ic2} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <h2>{homepagedata?.tokenname}</h2>
                                            <h3>Token Standard</h3>
                                        </div>
                                    </div>
                                    <div className="ethmtsj">
                                        <div>
                                            <img src={ic3} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <h2>{count}</h2>
                                            <h3>Active Users</h3>
                                        </div>
                                    </div>
                                    <div className="ethmtsj">
                                        <div>
                                            <img src={ic4} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <h2>{homepagedata?.Symbol}</h2>
                                            <h3>Ticker</h3>
                                        </div>
                                    </div>
                                    <div className="ethmtsj">
                                        <div>
                                            <img src={ic5} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <h2>{homepagedata?.totalsupply}</h2>
                                            <h3>Total Supply</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="table-responsive tablepdl">
                                {homepagedata?.chart && (
                                    <table className="table-borderless w-100">
                                        {homepagedata.chart.map((item, index) => {
                                            const colorRgb = item.color.startsWith('#') ? hexToRgb(item.color) : item.color;
                                            return (
                                                <tbody className="tbdyprgrd" key={item.id || index}>
                                                    <tr>
                                                        <td>
                                                            <div className="progress cus_prg">
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width: `${item.percentage}%`,
                                                                        background: `linear-gradient(-90deg, rgba(${colorRgb}, 0.5) 0%, rgba(${colorRgb}, 0) 100%)`
                                                                    }}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td><img src={colorImg[index]} alt="" className="img-fluid" /></td>
                                                        <td>
                                                            <h4><span>{item.title} </span></h4>
                                                        </td>
                                                        <td>
                                                            <h5>{item.percentage}%</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>
                                )}
                                <h4 className="blcktext">BLOCKWAVE Token Distribution </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg_tnks">
                </div>
            </div>
        </div>
    );

}

Tokenomics.propTypes = {
    homepagedata: PropTypes.object.isRequired, // Using object type
    count: PropTypes.number.isRequired,
};


export default Tokenomics;