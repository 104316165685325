import React from "react";
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';


const Faq = ({ homepagedata }) => {

    return (
        <div className="FAQSec">
            <div className="container">
                <h3>Frequently Asked Questions.</h3>
                <Accordion>
                    {homepagedata?.faq?.map((faqItem, index) => (
                        <AccordionItem key={faqItem.id || index} header={faqItem.question}>
                            {faqItem.answer}
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </div>
    );

}
Faq.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Faq;