import "../Buytoken/scss/buytoken.scss";

import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState } from "react";

import { useForm } from 'react-hook-form'
import { CONST } from "../../config";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link } from "react-router-dom";
import Helper from "../../common/Helper";


const Forgot = () => {
  toast.configure()
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
  const [button, setButton] = useState(false)
  const navigate = useNavigate()

  const onSubmit = async (data) => {
    setButton(true)
    const email = {
      'email': data.email
    }
    let getData = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/rCyn9NW7uhdC9WxEK2Ff`, email);
    if (getData?.status) {
      setButton(false)
      toast.success(getData.msg)
      navigate('/login')
    }
    else {
      setButton(false)
      toast.error(getData.msg)
    }
  }

  const AvoidSpace = (event) => {
    const k = event.which || event.keyCode;
    if (k === 32) {
      event.preventDefault();
    }
  };
  
  return (
    <Fragment>
      <div className="cntld">
        <div className="w-100 LoginRegContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <form className="loginRegForm" onSubmit={handleSubmit(onSubmit)}>
                  <div className="heading text-center">
                    <h3 className="mb-2">Forgot Password</h3>
                    <p>Enter Email address associated with your account</p>
                  </div>
                  <div className="my-3">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input type="text"
                      className="form-control"
                      placeholder="Email"
                      onKeyDown={(event) => AvoidSpace(event)}
                      {
                      ...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      })
                      }
                    />
                    {errors.email && errors.email.type === 'required' && <span className='errors'>Please Enter the Email</span>}
                    {errors.email && errors.email.type === 'pattern' && <span className='errors'>Please Enter valid Email</span>}
                  </div>
                  <div className="my-5">
                    <button className="btn btn-block orangeBtn w-100" disabled={button}>
                      {button ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Submit</span>}</button>
                  </div>
                  <Link to="/login" className="ms-auto">Back to Signin</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default Forgot;