import "../Pagenotfound/scss/pagenotfound.scss";

import React from "react";

import pagenotfoundImg from "../../assets/images/404.png";
import { useNavigate } from "react-router-dom";

const Pagenotfound = () => {

  let navigate = useNavigate();

  return (
    <div className="cntld">
      <div className="btpg mt-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 p-5">
              <h3>Something’s Missing </h3>
              <img src={pagenotfoundImg} className="img-fluid d-block mx-auto" alt="" />
            </div>
          </div>
        </div>
        <button className="btn btn-gr" onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );

}

export default Pagenotfound;