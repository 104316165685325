import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState, useEffect } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import downarrowImg from "../../assets/images/downarrow.svg";
import copy from "../../assets/images/copy.svg";
import leftarrowImg from "../../assets/images/leftarrow.png";
import { CONST } from "../../config";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helper from "../../common/Helper";
import { NumericFormat } from 'react-number-format';
import { BLOCK_EXPLORER } from "../../common/Explore_URLS";
import PropTypes from "prop-types";

const Withdraw = (props) => {
  toast.configure()
  const navigate = useNavigate()
  const [currencyDetails, setCurrencyDetails] = useState('')
  const [currency, setCurrency] = useState('Select Currency');
  const [curImg, setCurImg] = useState('')
  const [amount, setAmount] = useState('')
  const [address, setAddress] = useState('');
  const [tfa, setTfa] = useState('');
  const [loading, setLoading] = useState(false);
  let color = "#ffffff";
  const [checkHit, setCheckHit] = useState(false);
  const [tokens, setTokens] = useState('')
  const [status, setStatus] = useState(false)
  const [userId, setUserId] = useState('');
  const [kycStatus, setKYCStatus] = useState(false);
  const [maxWithdraw, setMaxWithdraw] = useState(false);
  const [minWithdraw, setMinWithdraw] = useState('');

  useEffect(() => {
    fetchData();
    fetchDataOne()
    fetchDataTwo()
  }, [])

  useEffect(() => {
    if (!checkHit) {
      setCheckHit(true)
      fetchCurrencyData()
    }
  }, [props.isCurrency])

  const [profile, setProfile] = useState('')
  const [history, setHistory] = useState('')
  let count = 0;

  const pageSize = [
    {
      length: '10',
      value: 10
    },
    {
      length: '25',
      value: 25
    },
    {
      length: '45',
      value: 45
    },
    {
      length: '100',
      value: 100
    },
    {
      length: 'All',
      value: ''
    }
  ]

  useEffect(() => {
    if (userId) {
      withdrawHistory(pageSize[0]?.value);
    }
  }, [userId]);

  const withdrawHistory = async (length) => {
    setLoading(true);
    const withdrawData = {
      "userId": userId,
      "page": 1,
      "length": length
    }
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/kEN4tUAUWkyFxITqrmJj`, withdrawData);
    if (getdata?.status) {
      setHistory(getdata?.data);
    }
    setLoading(false);
  }

  const fetchCurrencyData = async () => {
    let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`);
    if (getdata?.status) {
      let dataNew = getdata?.data;
      if (dataNew && dataNew.length > 0) {
        dataNew.map(data => {
          if (props.isCurrency === data.name) {
            getCurrency(data)
          }
        })
      }
    }
  }

  const fetchData = async () => {
    let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`);
    if (getdata?.status) {
      setCurrencyDetails(getdata?.data?.filter((temp) => temp?.symbol !== ""));
      getdata?.data?.map(data => {
        if (props.isCurrency === data.name) {
          setCurrency(props.isCurrency)
          setCurImg(data.image)
          getwithdrawFee(props.isCurrency);
          if (status === true) {
            let i;
            for (i = 0; i < balance.length - 1; i++) {
              if (data == balance[i].currency) {
                setTokens(balance[i].amount)
              }
            }
          }
        }
      })
    }
  }

  const fetchDataOne = async () => {
    let getData = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
    if (getData?.status === true) {
      setUserId(getData?.user?.id);
      setProfile(getData?.user?.tfa_code);
      setKYCStatus(getData?.user?.kycfinal_status);
    }
  }

  const [netType, setNetType] = useState('Select Network');
  const [balance, setBalance] = useState([])
  const [otpstatus, setOtpstatus] = useState(false)
  const [reotpstatus, setReotpstatus] = useState(false)
  const [resendotp, setResendotp] = useState(false)
  const [currencyContent, setCurrencyContent] = useState('');

  const getCurrency = async (data) => {
    setLoading(true);
    setCurrency(data.name)
    setCurImg(data.image)
    getwithdrawFee(data.name);
    setCurrencyContent(data?.withdraw_content);
    let temp;
    let data1 = {
      'name': data.name,
      'networktype': '',
    }
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/ogD9GHWOPd7n9cSEjlY7`, data1);
    if (getdata?.status) {
      temp = getdata?.data;
      setNetType(temp[0].networktype)
      setLoading(false);
    }
    if (data.name && data.name == 'Select Currency') {
      document.getElementById('error').innerHTML = 'Please Choose the Currency'
    }
    else if (data.name && data.name !== 'Select Currency') {
      document.getElementById('error').innerHTML = ''
      setLoading(true);
      props.setIsCurrency('')
      let i;
      for (i = 0; i < balance?.length; i++) {
        if (data?.name == balance[i]?.currency) {
          setTokens(balance[i]?.amount)
        }
      }
      setLoading(false);
    }
  }



  const currencyName = (e) => {
    if (currency && currency == 'Select Currency') {
      document.getElementById('error').innerHTML = 'Please Choose the Currency'
    }
    else if (currency && currency !== 'Select Currency') {
      document.getElementById('error').innerHTML = ''
    }
    props.setCurrency('')
  }

  const [withdrawFee, setWithdrawFee] = useState('')
  const [networkFee, setNetworkFee] = useState('')
  const [withdrawFeeType, setWithdrawFeeType] = useState('');
  const [withdrawclaim, setWithdrawclaim] = useState('');

  const amountName = (inputObj) => {
    const { value } = inputObj;
    if (value <= maxWithdraw) {
      setAmount(value);
      document.getElementById('error1').innerHTML = ''
      if (withdrawFeeType == 'Flat') {
        setWithdrawclaim((value) - (withdrawFee + (+networkFee)))
      }
      else if (withdrawFeeType == 'Percentage') {
        let overAllFee = (+withdrawFee + (+networkFee))
        let withdrawValue = +value * (overAllFee / 100);
        setWithdrawclaim(+value - withdrawValue);
      }
      return true;
    }
    return false;
  }

  const addressName = (e) => {
    setAddress(e.target.value)
    document.getElementById('error2').innerHTML = ''
  }

  const tfaName = (e) => {
    if (e.target.value && e.target.value === '' || !e.target.value) {
      setTfa('')
      document.getElementById('error3').innerHTML = 'Please Enter OTP Code'
    }
    else if (!(/^\d{6}$/.test(e.target.value))) {
      document.getElementById('error3').innerHTML = 'Please Enter Valid OTP Code';
      setTfa('');
    }
    else {
      setTfa(e.target.value)
      document.getElementById('error3').innerHTML = ''

    }
  }

  const getwithdrawotpData = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (currency === 'Select Currency' && amount == '' && address == '') {
      setLoading(false)
      document.getElementById('error').innerHTML = 'Please Choose the Currency';
      document.getElementById('error1').innerHTML = 'Please Enter the Amount'
      document.getElementById('error2').innerHTML = 'Please enter the valid address'
      document.getElementById('error3').innerHTML = 'Please Enter the OTP Code'
    }
    else if (currency === 'Select Currency') {
      setLoading(false)
      document.getElementById('error').innerHTML = 'Please Choose the Currency';
    }
    else if (amount === '' || amount == 0) {
      setLoading(false)
      document.getElementById('error1').innerHTML = 'Please Enter the Amount'
    }
    else if (address === '') {
      setLoading(false)
      document.getElementById('error2').innerHTML = 'Please Enter the valid address'
    }
    else if (+tokens <= +amount) {
      toast.error('Insufficient Balance !')
      setLoading(false);
    }
    else if (currency !== 'Select Currency' && amount !== '' && address !== '') {
      document.getElementById('error').innerHTML = ''
      document.getElementById('error2').innerHTML = ''
      document.getElementById('error1').innerHTML = ''
      let data1 = {
        'currency': currency,
        'amount': amount,
        'address': address,
        "type": netType
      }
      if (minWithdraw <= amount) {
        if (resendotp) {
          setResendotp(true)
        }
        let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/xwDCYl2qZzLrzzQeQI5O`, data1);
        if (getdata?.status) {
          setLoading(false);
          setOtpstatus(true)
          toast.success(getdata?.msg);
        }
        else if (!getdata?.status) {
          setLoading(false);
          toast.error(getdata?.msg)
        }
      }
      else {
        toast.error(`Amount Must be Higher then  Minimum Withdraw Amount !`);
        setLoading(false);
      }
    }
  }

  const getwithdrawData = async (e) => {
    setReotpstatus(false)
    setTfa('')
    e.preventDefault();
    setLoading(true);
    if (currency === 'Select Currency' && amount == '' && address == '' && tfa == '') {
      document.getElementById('error').innerHTML = 'Please Choose the Currency';
      document.getElementById('error1').innerHTML = 'Please Enter the Amount'
      document.getElementById('error2').innerHTML = 'Please enter the valid address'
      document.getElementById('error3').innerHTML = 'Please Enter the OTP Code'
      setLoading(false)
    }
    else if (currency === 'Select Currency') {
      document.getElementById('error').innerHTML = 'Please Choose the Currency';
      setLoading(false)
    }
    else if (amount === '' || amount == 0) {
      document.getElementById('error1').innerHTML = 'Please Enter the Amount'
      setLoading(false)
    }
    else if (address === '') {
      document.getElementById('error2').innerHTML = 'Please Enter the valid address'
      setLoading(false)
    }
    else if (+tokens <= +amount) {
      toast.error('Insufficient Balance !')
      setLoading(false);
    }
    else if (currency !== 'Select Currency' && amount !== '' && address !== '') {
      document.getElementById('error').innerHTML = ''
      document.getElementById('error2').innerHTML = ''
      document.getElementById('error1').innerHTML = ''
      let data1 = {
        'currency': currency,
        'amount': amount,
        'address': address,
        'otp': tfa,
        "type": netType
      }
      if (minWithdraw <= amount) {
        let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/XxDuksyDcXpGwsQJwg6s`, data1);

        if (getdata?.status) {
          setCurrency('Select Currency');
          setAddress(' ');
          setAmount('');
          setTfa('');
          setLoading(false);
          otpstatus(true)
          toast.success(getdata?.msg);
          props.setIsCurrency('');
          withdrawHistory(pageSize[0]?.value);
        }
        else if (!getdata?.status && getdata?.otpstatus == "ressend") {
          setOtpstatus(false)
          setReotpstatus(true)
          setLoading(false);
          toast.error(getdata?.msg)
        }
        else if (!getdata?.status) {

          setLoading(false);
          toast.error(getdata?.msg)
        }
      }
      else {
        toast.error(`Amount Must be Higher then  Minimum Withdraw Amount !`);
        setLoading(false);
      }
    }
  }

  const Back = () => {
    navigate('/wallet')
  }

  const fetchDataTwo = async () => {
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/gl79YP2VipvfMO72obJM`);
    if (getdata?.status) {
      setBalance(getdata.msg);
      setStatus(true)
      let i;
      for (i = 0; i < getdata?.msg?.length - 1; i++) {
        if (props.isCurrency == getdata?.msg[i]?.currency) {
          setTokens(getdata?.msg[i]?.amount)
        }
      }
    }

  }

  const getwithdrawFee = async (data) => {
    let getFee = await Helper.PostData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/GGOGplBJ9ui65Fjag2hQ`, { 'name': data });
    if (getFee?.status) {
      setWithdrawFee(getFee?.data[0].withdraw_fee);
      setWithdrawFeeType(getFee?.data[0].fee_type);
      setNetworkFee(getFee?.data[0].network_fee);
      setMaxWithdraw(getFee?.data[0]?.max_withdraw);
      setMinWithdraw(getFee?.data[0]?.min_withdraw);
      if (getFee?.data[0].fee_type == 'Flat') {
        setWithdrawclaim((amount) - (getFee?.data[0].network_fee + getFee?.data[0].withdraw_fee))
      }
      else if (getFee?.data[0].fee_type == 'Percentage') {
        let overAllFee = (+getFee?.data[0].withdraw_fee) + (+getFee?.data[0].network_fee)
        let withdrawValue = +amount * (overAllFee / 100);
        setWithdrawclaim(+amount - withdrawValue);
      }
    }
  }

  const renderTransactionID = (data) => {
    const ref = `${(data?.reference_no).slice(0, 4)}...${(data.reference_no).slice(-4)}`;
    const explorerLink =
      data.currency === "BLOCKWAVE" ? `${BLOCK_EXPLORER?.BLOCKWAVE}${data.reference_no}` :
        data.currency === "ETH" ? `${BLOCK_EXPLORER?.ETH}${data.reference_no}` :
          `${BLOCK_EXPLORER?.USDT}${data.reference_no}`;

    return (
      <td>
        {ref}
        <a href={explorerLink} target="_blank" rel="noopener noreferrer">
          <img src={copy} className="ms-2" alt="" />
        </a>
      </td>
    );
  };

  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="w-100 walletContainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="DepositWithdrawCnt">
                  <div className="head d-flex align-items-center">
                    <img
                      src={leftarrowImg}
                      className="me-2 pointer"
                      onClick={Back}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Space') {
                          Back();
                        }
                      }}
                      alt=""
                    />
                    <h3 className="mb-0">Withdraw</h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <form>
                        <div className="row">
                          <div className="col-lg-5 mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Select Currency<span className="mandatory">*</span></label>
                            <UncontrolledDropdown  >
                              <DropdownToggle caret onChange={currencyName}>
                                <div className="d-flex align-items-center">
                                  {curImg ?
                                    <img src={curImg} className="me-2 icon-size" alt="" />
                                    :
                                    <></>
                                  }
                                  {currency}
                                  <img src={downarrowImg} className="ms-auto" alt="" />
                                </div>
                              </DropdownToggle >
                              <span id='error' style={{ color: "red" }}></span>
                              <DropdownMenu >
                                {currencyDetails && currencyDetails.map((data, index) => (
                                  <>
                                    {data.withdraw_status == "active" ?

                                      <DropdownItem tag="a" key={data.id || index} onClick={() => getCurrency(data)}>
                                        <div className="d-flex align-items-center">
                                          <img src={data.image} height="22" className="me-2" alt=""
                                          />
                                          {data.name}
                                        </div>
                                      </DropdownItem>
                                      :
                                      <></>
                                    }
                                  </>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            {currency !== 'Select Currency' ?
                              <label htmlFor="exampleInputEmail1" className="form-smlabel text-end">Balance:{tokens} {currency}</label> : <></>}
                          </div>
                          <div className="col-lg-5 mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Receiver Wallet Address<span className="mandatory">*</span></label>
                            <input type="text" className="form-control" readOnly={otpstatus || reotpstatus} value={address} placeholder="Enter Wallet Address" onChange={addressName} />
                            {currency == 'Select Currency' ? <></> : <>
                              <span style={{ color: "white", fontSize: "10px" }}>Withdraw Fee : {withdrawFeeType == 'Flat' ? <>{withdrawFee} {currency}</> : <>{withdrawFee}%</>}</span>
                              <span style={{ color: "white", fontSize: "12px", marginLeft: '8rem' }}>Your Withdraw Amount : {withdrawclaim <= 0 || withdrawclaim == '' ? 0 : withdrawclaim}</span><br />
                              <span style={{ color: "white", fontSize: "10px" }} >Network Fee : {withdrawFeeType == 'Flat' ? <>{networkFee} {currency}</> : <>{networkFee}%</>}</span>
                              <span id='error2'></span>
                            </>
                            }
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Amount To Send<span className="mandatory">*</span></label>
                            <NumericFormat
                              className="form-control" readOnly={otpstatus || reotpstatus}
                              disabled={currency === 'Select Currency'}
                              value={amount} decimalScale={8} isAllowed={amountName} placeholder="Enter Amount"
                              required
                            />
                            {minWithdraw && maxWithdraw ?
                              <>
                                <span className="p-2 form-smlabel">Min Withdraw Amount : {minWithdraw}</span>
                                <span className="p-2 form-smlabel">Max Withdraw Amount : {maxWithdraw}</span>
                              </>
                              : <></>}
                            <br />
                            <span id='error1'></span>
                          </div>
                          {otpstatus || resendotp ?
                            <div className="col-lg-5 mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">Enter OTP<span className="mandatory">*</span></label>
                              <NumericFormat
                                className="form-control" maxLength="6" value={tfa} onChange={tfaName} placeholder="Enter OTP"
                                required
                              />
                              <br />
                              <span id='error3'></span>
                            </div>
                            : ""}
                        </div>
                        {currency && currency == "undefined" || currency && currency == "" || address == "undefined" || address == "" || (!address) || profile == "undefined" || profile == "" || !(amount) || amount == "" || amount == "undefined" ?
                          <div className="row">
                            <div className="col-lg-5 mb-3">
                              <button className="btn orangeBtn" onClick={!kycStatus ? () => navigate("/kyc") : otpstatus ? (e) => getwithdrawData(e) : reotpstatus ? (e) => getwithdrawotpData(e) : (e) => getwithdrawotpData(e)}>
                                {
                                  !kycStatus ? 'Complete KYC' : otpstatus ? 'Submit' : reotpstatus ? "Resend OTP" : 'Send OTP'
                                }
                              </button>
                            </div>
                          </div>
                          :
                          <div className="row">
                            <div className="col-lg-5 mb-3">
                              <button className="btn orangeBtn" onClick={!kycStatus ? () => navigate("/kyc") : otpstatus ? (e) => getwithdrawData(e) : reotpstatus ? (e) => getwithdrawotpData(e) : (e) => getwithdrawotpData(e)}>
                                {
                                  !kycStatus ? 'Complete KYC' : otpstatus ? 'Submit' : reotpstatus ? "Resend OTP" : 'Send OTP'
                                }
                              </button>
                            </div>
                          </div>
                        }
                        {props.isCurrency == '' && currency == 'Select Currency' ? <></> :
                          <div className="row">
                            {
                              currencyContent?.length > 0 ?

                                <div className="col-lg-5 mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Notes:</label>
                                  <div className="notesCnt">
                                    {currencyContent}
                                  </div>
                                </div>
                                :
                                null
                            }
                          </div>
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="walletHistoryCnt">
          <div className="histpg">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">
                  <div className="histtab p-4">
                    <div className='row'>
                      <div className='col-md-8 mr-1'>
                        <h3 className="p-4">Withdraw History</h3>
                      </div>
                      <div className='col-md-4 p-4 mr-4'>
                        <select className="form-control" disabled={history?.length === 0 ? true : false} onChange={(e) => withdrawHistory(e.target.value)}>
                          {
                            pageSize?.map((temp, index) =>
                              <option key={temp.id || index} value={temp.value}>{temp?.length}</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive wrapper1">
                      <table className="table mb-0">
                        <thead className="sticky">
                          <th>S.No</th>
                          <th>Date & Time</th>
                          <th>Amount</th>
                          <th>Transfer Amount</th>
                          <th>Transaction ID</th>
                          <th>Status</th>
                        </thead>
                        <tbody>
                          {history?.length > 0 ? (
                            history.map((data, index) => (
                              <tr key={data.id || index}>
                                <td>{count += 1}</td>
                                <td>{Helper.formatDated(data?.updated_at)}</td>
                                <td>{data.amount} <span>{data.currency}</span></td>
                                <td>{data.transfer_amount}</td>
                                {renderTransactionID(data)}
                                <td className="text-success">{data?.status}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">
                                <span className="nodata m-0">No Data Found</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table >
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </div >
        </div >
      </div >
      <Footer />
    </Fragment >
  );
}

Withdraw.propTypes = {
  isCurrency: PropTypes.any.isRequired, // Add this line for prop validation
  setIsCurrency: PropTypes.any.isRequired, // Add this line for prop validation
  setCurrency: PropTypes.any.isRequired,
};

export default Withdraw;