import "../History/scss/history.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState, useEffect } from "react";
import { } from "reactstrap";
import leftarrowImg from "../../assets/images/leftarrow.png";
import { CONST } from "../../config";
import { useNavigate } from "react-router-dom";
import Helper from "../../common/Helper";
import { useCustomizeContext } from "../../common/Context";
import ScaleLoader from "react-spinners/ScaleLoader";

const History = () => {
  const navigate = useNavigate()
  const [history, setHistory] = useState()
  const [loading, setLoading] = useState(false);
  let color = "#ffffff";

  let count = 0;
  const {
    userInfo: {
      userId,
    }
  } = useCustomizeContext();

  useEffect(() => {
    getHistory();
  }, [userId ?? userId])

  const getHistory = async () => {
    setLoading(true);
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/whOjDXAfqBzWejKKr5`, '');
    if (getdata?.status) {
      setHistory(getdata?.data)
    }
    setLoading(false);
  }

  const Back = () => {
    navigate('/buytoken')
  }
  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="histpg HistraPg p-4 " style={{ marginTop: "120px" }}>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 histtab p-0">
                <div className="head d-flex align-items-center ">
                  <img
                    src={leftarrowImg}
                    className="mx-2 pointer pt-3"
                    onClick={Back}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        Back();
                      }
                    }}
                    width="10px"
                    alt=""
                  />
                  <h3 className="pt-4 pl-2">History</h3>
                </div>
                <div className="table-responsive wrapper1">
                  <table className="table">
                    <thead className="sticky mb-0">
                      <th>S.No</th>
                      <th>Date & Time</th>
                      <th>Type</th>
                      <th>Spend Currency</th>
                      <th>Receive Currency</th>
                    </thead>
                    <tbody>
                      {history && history.length > 0 ?
                        history && history.map((data, index) => (
                          <tr key={data.id || index}>
                            <td>{count = count + 1}</td>
                            <td>{Helper?.formatDated(data?.created_at)} </td>
                            {data.type == "BUY" ?
                              <td style={{ color: "green" }}>{data.type}</td>
                              :
                              <td style={{ color: "red" }}>{data.type}</td>
                            }                                {data.type == "CLAIM" ?
                              <>
                                <td>-</td>
                                <td>{data.request_amount}<span>{data.currency}</span></td>
                              </>
                              :
                              <>
                                <td>{data.spent_amount ? (data.spent_amount).toFixed(8) : <>-</>} <span>{data.currency}</span></td>
                                <td>{data.request_amount ? (data.request_amount).toFixed(8) : <>-</>} <span>{data.tocurrency}</span></td>
                              </>
                            }
                          </tr>
                        )) : <tr><td colSpan="7"><span className="">No Data Found</span></td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default History;