import "../Underconstruction/scss/underconstruction.scss";

import React from "react";
import { } from "reactstrap";
import maintanenceImg from "../../assets/images/maintanence.png";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { CONST } from "../../config";

import 'react-toastify/dist/ReactToastify.css';
import Helper from "../../common/Helper";
const Underconstruction = () => {

  toast.configure()

  const navigate = useNavigate()

  const Back = async () => {

    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/xh7lBgripKGhlAaFotjw`);
    if (getdata?.status) {
      if (getdata?.data?.maintance_detail == "undermaintanence") {
        toast.error("Site is Undermaintenance")
      }
      else {
        navigate('/')
      }
    }
  }
  return (
    <div className="cntld">
      <div className="ucpg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h3>Website Under Construction</h3>
              <p>This page is under development!</p>
              <button className="btn bluebtn" onClick={Back}>Back to Home</button>
            </div>
            <div className="col-lg-6">
              <img src={maintanenceImg} className="d-block mx-auto img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Underconstruction;