import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import React, { Fragment, useState } from "react";

import login from "../../assets/images/login.png";
import { useForm } from "react-hook-form";
import axios from 'axios'
import { CONST } from "../../config";

import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import { cryptoModule } from "../../common/Services";

const Resetpassword = () => {
  toast.configure()
  const [button, setButton] = useState(false)
  const [showeyenew, setShoweyenew] = useState(false);
  const [showeyecon, setShoweyecon] = useState(false);
  const navigate = useNavigate()
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({ mode: "onTouched" });


  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const onSubmit = async (data) => {
    setButton(true)
    let data1 = {
      'verify': params.verify,
      'token': params.token,
      'password': data.password,
      'confirm_password': data.cpassword,
    }
    let getdata = await axios.post(CONST.BACKEND_URL + '/Yq7ss7Xwd8Jc/YnD2YmNtRIVMuP57uudZ', data1)
    if (getdata.data.status) {
      setButton(false)
      sessionStorage.setItem('email', cryptoModule.encrypt(data?.email));
      sessionStorage.setItem('password', cryptoModule.encrypt(data?.password));
      if (getdata.data.status) {
        setButton(false)
        toast.success(getdata.data.msg)
        setTimeout(() => {
          navigate('/login')
        }, 1000)
      }

    }
    else {
      setButton(false)
      toast.error(getdata.data.msg)
    }
  }

  const AvoidSpace = (event) => {
    const k = event.which || event.keyCode;
    if (k === 32) {
      event.preventDefault();
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="cntld">
        <div className="w-100 LoginRegContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <img src={login} alt="" />
                  </div>
                  <div className="col-lg-6">
                    <form className="loginRegForm" onSubmit={handleSubmit(onSubmit)}>
                      <div className="heading">
                        <h5>Welcome back</h5>
                        <h3>Reset Password</h3>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="newPasswrod">New Password</label>
                        <div className="eyeIcon">
                          <input
                            type={showeyenew ? 'text' : 'password'}
                            className="form-control"
                            placeholder="New Password"
                            onKeyDown={(event) => AvoidSpace(event)}

                            {...register('password', { required: { value: true, message: "Password Is Required" }, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })}
                          />
                          <button className="btn" type="button" onClick={() => setShoweyenew(!showeyenew)}>
                            {showeyenew ?
                              <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                              :
                              <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                            }
                          </button>
                        </div>
                        {errors.password && errors.password.type === 'required' && (
                          <span className="errors">{errors.password.message}</span>
                        )}
                        {errors.password && errors.password.type === 'pattern' && <span className='errors '>Password Must Contain Uppercase, Special Characters, Numbers</span>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                        <div className="eyeIcon">
                          <input
                            type={showeyecon ? 'text' : 'password'}
                            className="form-control"
                            placeholder="Confirm Password"
                            onKeyDown={(event) => AvoidSpace(event)}
                            {...register('cpassword', { required: true, validate: (value) => value === getValues("password") })}
                          />
                          <button className="btn" type="button" onClick={() => setShoweyecon(!showeyecon)}>
                            {showeyecon ?
                              <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                              :
                              <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                            }
                          </button>
                        </div>
                        {errors.cpassword && errors.cpassword.type === 'required' && <span className='errors'>Confirm Password is Required</span>}
                        {errors.cpassword && errors.cpassword.type === 'validate' && <span className='errors'>Passwords Must be Same</span>}
                      </div>
                      <div className="my-5">
                        <button className="btn btn-block orangeBtn w-100" disabled={button}>
                          {button ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Submit</span>}</button>
                      </div>
                      <div className="mb-3">
                        <p className="text-center">Dont have an account? <a href="#">Join free today</a></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

}

export default Resetpassword;