import CryptoJS from 'crypto-js';
// eslint-disable-next-line no-undef
const cryptoKey = process.env.REACT_APP_KEY;

export const cryptoModule = {

    encrypt: function (txt) {
        return CryptoJS.AES.encrypt(txt, cryptoKey).toString();
	},
	decrypt: function (txt) {
		if (txt !== undefined) {
            return CryptoJS.AES.decrypt(txt, cryptoKey).toString(CryptoJS.enc.Utf8)
		}
	},
    
};




