import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState, useEffect } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupText, Input, TabContent, TabPane, Row, Col } from "reactstrap";
import refreshImg from "../../assets/images/refresh.svg";
import downarrowImg from "../../assets/images/downarrow.svg";
import btcImg from "../../assets/images/hold.png";
import { Link, useNavigate } from 'react-router-dom'
import ScaleLoader from "react-spinners/ScaleLoader";
import { CONST } from '../../config/index'
import { v4 as uuidv4 } from 'uuid';
import '../../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helper from "../../common/Helper";
import { NumericFormat } from "react-number-format";
import ClipLoader from "react-spinners/ClipLoader";


const Home = (props) => {

  toast.configure();
  const [currencyDetails, setCurrencyDetails] = useState('')
  const [currency, setCurrency] = useState('Select Currency');
  const [curSymbol, setCurSymbol] = useState();
  const [curAmount, setCurAmount] = useState();
  const [usdtPrice, setUsdtPrice] = useState();
  const [currencyAmt, setCurrencyAmt] = useState("");
  const [blissAmt, setBlissAmt] = useState();
  const [code, setCode] = useState();
  const [curimg, setCurimg] = useState();
  const [inputlength, setInputlength] = useState(true);
  const [loading, setLoading] = useState(false);
  let color = "#ffffff";
  const [savebutton, setSavebutton] = useState(false);
  const [kycStatus, setKYCStatus] = useState(false);
  const [feeBwt, setFeeBwt] = useState('')
  const currentActiveTab = '1';

  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
    getUsdtData();
  }, [])

  useEffect(() => {
    getUserInfo();
  }, []);

  const fetchData = async () => {
    let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`);
    if (getdata?.status) {
      setFeeBwt(getdata?.data?.find((temp) => temp?.symbol === "BLOCKWAVE"))
      setCurrencyDetails(getdata?.data?.filter((temp) => temp?.symbol !== "BLOCKWAVE"))
    }
  }

  const getUsdtData = async () => {
    setLoading(true);
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/WvbG7yEwoMsDinp5XdZ7`);
    if (getdata?.status) {
      setUsdtPrice(parseFloat(getdata?.data[0]?.blockwaveprice))
    }
    setLoading(false);
  }

  const getTokenPrice = async (postdata) => {
    return await Helper.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/Y0WqfHFU2Ezw9Txfftzs`, { 'type1': postdata });
  }

  const getData = async (data) => {
    if (data === "BTC") {
      setLoading(true);
      let res = await getTokenPrice(data);
      if (res?.status) {
        let result = parseFloat(res?.data?.BTCprice / usdtPrice)
        setBlissAmt(result);
        setCurrencyAmt((((usdtPrice) / (parseFloat(res?.data?.BTCprice))) * parseFloat(curAmount)).toFixed(14))
      }
      setLoading(false);
    }
    else if (data === "USDT") {
      setLoading(true);
      let res = await getTokenPrice(data);
      if (res?.status) {
        let result = parseFloat(res?.data?.USDTprice / usdtPrice)
        setBlissAmt(result);
        setCurrencyAmt((((usdtPrice) / (parseFloat(res?.data.USDTprice))) * parseFloat(curAmount)).toFixed(14))
      }
      setLoading(false);
    }
    else if (data === "ETH") {
      setLoading(true);
      let res = await getTokenPrice(data);
      if (res?.status) {
        let result = parseFloat(res?.data?.ETHprice / usdtPrice)
        setBlissAmt(result);
        setCurrencyAmt((((usdtPrice) / (parseFloat(res?.data.ETHprice))) * parseFloat(curAmount)).toFixed(14))
      }
      setLoading(false);
    }
  }

  // GET USER PROFILE
  const getUserInfo = async () => {
    let getData = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
    if (getData?.status) {
      setKYCStatus(getData?.user?.kycfinal_status);
    }
  }

  const [balance, setBalance] = useState('');

  const getBalance = async (data) => {
    setLoading(true);
    let balanceData = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/IYW26gnMLTxtAYvpXHyc`, { 'currency': data });
    if (balanceData?.status) {
      setBalance(balanceData?.data?.wallet[0]);
    }
    setLoading(false);
  }

  const [addr, setAddr] = useState('');

  const getCurrency = async (data) => {
    getUsdtData();
    setCurrency(data.name)
    setCurSymbol(data.symbol)
    setCurimg(data.image)
    setCode(uuidv4());
    if (localStorage.getItem("loginId")) {
      getData(data.symbol)
      getBalance(data.symbol)
      setLoading(true);
      let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/e9Q3grKrNfiKv7Z0mOUD`, { "currency": data?.name });
      if (getdata?.status && getdata?.data?.length > 0) {
        setAddr(getdata?.data[0]?.address);
      }
      else if (getdata?.status && getdata?.data?.length === 0) {
        toast.error(`Please Generate the address for ${data?.name}`);
        setTimeout(() => {
          navigate('/deposit');
        }, 1000);
      }
      else {
        toast.error(getdata?.msg)
      }
      setLoading(false);
    }
  }


  const getAmount = async (e) => {
    if (e.target.value < 0) {
      toast.error("Enter Valid Amount");
      setCurrencyAmt(0);
    } else if (e.target.value === '0') {
      setCurrencyAmt(0);
    } else {
      setCurAmount(e.target.value);
      setInputlength(true);
      setCurrencyAmt((e.target.value * blissAmt).toFixed(8));
      localStorage.setItem('refNo', code);
    }
  };

  const token = localStorage.getItem('loginId');

  const buynow = async (event, values) => {
    setSavebutton(true);
    if (currencyAmt == undefined || currencyAmt === "" || isNaN(currencyAmt) || currencyAmt <= 0) {
      toast.error("Your Spent Amount is Empty");
    } else if (curAmount == undefined || curAmount === "" || isNaN(curAmount) || curAmount <= 0) {
      toast.error("Your Request Amount is Empty");
    } else if (+balance?.amount <= +currencyAmt) {
      toast.error('Insufficient Fund!');
    }

    else {
      setLoading(true);
      let data =
      {
        "user_address": (addr).toLowerCase(),
        "request_amount": parseFloat(curAmount),
        "spent_amount": currencyAmt,
        "currency": currency,
        "type": "BUY",
        "tocurrency": "BLOCKWAVE",
        "type1": curSymbol
      }
      let getData = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/RdrXQCCfbfGXmf2p`, data);
      if (getData?.status) {
        setSavebutton(false)
        toast.success(getData?.msg)
        setTimeout(() => {
          getBalance(currency);
        }, 1000)
        setCurAmount("");
        setCurrencyAmt("");
      }
      else {
        toast.error(getData?.msg)
        setSavebutton(false);
      }
      setLoading(false);
    }
    setSavebutton(false);
  }

  const calculateBuyAmount = (curAmount, feeBwt) => {
    if (!curAmount || !feeBwt.buy_token_fee) return 0;
    const fee = parseFloat(feeBwt.buy_token_fee) / 100;
    return (curAmount - curAmount * fee);
  };


  const buyAmount = calculateBuyAmount(curAmount, feeBwt);

  const isDisabled = (currency, curAmount, inputlength, currencyAmt) => {
    return (
      (currency && currency === 'select currency') ||
      curAmount === undefined ||
      !curAmount ||
      curAmount <= 0 ||
      inputlength === false ||
      currencyAmt === "NaN"
    );
  };
  
  const handlePrimaryClick = () => {
    if (token && !kycStatus) {
      return () => navigate('/kyc');
    } else if (token && kycStatus) {
      return () => buynow();
    } else {
      return () => navigate('/login');
    }
  };
  
  const primaryButtonText = () => {
    if (token && kycStatus) {
      return 'Buy';
    } else if (token && !kycStatus) {
      return 'Complete Your KYC';
    } else {
      return 'LOGIN';
    }
  };
  
  const handleSecondaryClick = () => {
    return !kycStatus ? () => navigate('/kyc') : () => buynow();
  };
  
  const secondaryButtonText = () => {
    return !kycStatus ? 'Complete Your KYC' : 'Buy';
  };

  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="buyTokenContainer1">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="btdv p-4">
                  <h3 className="text-center">BWT Token</h3>
                  <Link to='/history'>
                    <img src={refreshImg} className="refimg" alt="" />
                  </Link>
                  {/* ------------------ BUY TOKEN -------------- */}

                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div className="tknlst mb-3 mt-3">
                            <label>Spent Currency</label>
                            <UncontrolledDropdown>
                              <DropdownToggle caret>
                                <div className="d-flex justify-content-between align-items-center" >
                                  <div className="ldv">
                                    {curimg ?
                                      <img src={curimg} className="ms-2 icon-size" alt="" />
                                      :
                                      <></>
                                    }
                                    <span className="ms-2" >{currency}</span>
                                  </div>
                                  <img src={downarrowImg} alt="" />
                                </div>
                              </DropdownToggle>

                              <DropdownMenu>
                                {currencyDetails && currencyDetails.map((user, index) => (
                                  <>
                                    {user.currecnystatus == "active" ?
                                      <DropdownItem key={user.id || index} onClick={() => getCurrency(user)}>
                                        <img className='icon-size' src={user.image} alt="" /><span className="ms-2">{user.name}</span>
                                      </DropdownItem>
                                      :
                                      <></>
                                    }
                                  </>


                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <div className="mktplc mt-2 flex-wrap">
                              {blissAmt === undefined ?
                                <></>
                                :
                                <label className="val" htmlFor="exampleBalance"> Balance : {balance ? balance?.amount : <></>} {balance ? balance?.currency : <></>}</label>
                              }
                              {blissAmt === undefined ? <></> :
                                <label className="val" htmlFor="exampleCoin">1 BWT = {(parseFloat(blissAmt)).toFixed(8)} {curSymbol} </label>

                              }
                            </div>
                          </div>
                          <div className="tknlst mb-2">
                            <label htmlFor="exampleIToken">Enter BWT Token</label>
                            <InputGroup>
                              <InputGroupText>
                                <img src={btcImg} className="icon-size" alt="" />
                              </InputGroupText>
                              {currency && currency == 'Select Currency' ? <Input placeholder="Enter the BWT Amount" disabled />
                                :
                                <NumericFormat className="form-control" value={curAmount} placeholder="Enter the BWT Amount"
                                  disabled={!localStorage.getItem("loginId")} maxLength={10} decimalScale={8} onChange={getAmount} />
                              }
                            </InputGroup>
                            <div className="mktplc mt-2 flex-wrap">
                              {feeBwt?.buy_token_fee === undefined ?
                                <></>
                                :
                                <label className="val" htmlFor="exampleAmount">
                                  Buy Amount :{buyAmount}
                                </label>
                              }
                              {feeBwt?.buy_token_fee === undefined ? <></> :
                                <label className="val" htmlFor="exampleFee">BWT Fee : {(parseFloat(feeBwt?.buy_token_fee))}% </label>

                              }
                            </div>
                          </div>
                          <div className="usdtvl mb-4">
                            {(!curAmount) ? <span name='amount' ></span> :
                              <span name='amount' value={(parseFloat(currencyAmt)).toFixed(8)}>{(parseFloat(currencyAmt)).toFixed(8)}</span>
                            }
                            <div className="rdiv ">
                              {currency == 'Select Currency' ? <></> :
                                <img className='icon-size' src={curimg} alt="" />}
                              <span className="val ms-2">{curSymbol}</span>
                            </div>
                          </div>

                          {isDisabled(currency, curAmount, inputlength, currencyAmt) ? (
                            <button className="btn orangeBtn w-100" onClick={handlePrimaryClick()}>
                              {primaryButtonText()}
                            </button>
                          ) : (
                            <button className="btn orangeBtn w-100" onClick={handleSecondaryClick()} disabled={savebutton}>
                              {savebutton ? (
                                <ClipLoader color={'#ffffff'} size={25} />
                              ) : (
                                <span>{secondaryButtonText()}</span>
                              )}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </TabPane>



                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment >

  );

}

export default Home;