import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { CONST } from "../../config";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helper from ".././../common/Helper/index";

const VerifyToken = () => {
    toast.configure()
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('otp');


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {

        const data1 = {
            "verification_id": query
        }
        let data = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/f08oNDAG3XD4aZ6KCyYL`, data1);
        if (data?.status ) {
            toast.success(data?.msg)
            setTimeout(() => {
                navigate('/')
                window.location.reload()
            }, 1000);
        }
        else {
            toast.error(data?.msg)
            setTimeout(() => {

                navigate('/register')
                window.location.reload()
            }, 1000);
        }
    }

    return (
        <></>
    )
}
export default VerifyToken;