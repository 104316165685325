import Header from "../../common/Header/Header";
import Footer from "../../common/Footer12/Footer";
import { useEffect, useState } from "react";
import { CONST } from "../../config";
import Helper from "../../common/Helper";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";


const Privacy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    useEffect(() => {
        getData()
    }, []);

    const [cmsdata, setCmsdata] = useState()
    const [loading, setLoading] = useState(false);
    let color = "#ffffff";
    const [privacytitle, setPrivacytitle] = useState();
    const [privacytime, setPrivacytime] = useState();



    const getData = async () => {
        setLoading(true);
        let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/EKu1mCix4vAxDWLki7Vq`);
        if (getdata?.status) {
            setCmsdata(getdata.cmsdata[1])
            setPrivacytitle(getdata.cmsdata[1].title)
            const formattedDate = moment(getdata.cmsdata[1].updatedAt).format('MMM D, YYYY');
            setPrivacytime(formattedDate);
        }
        setLoading(false);
    }



    return (
        <>
            <div className={loading ? "spinner" : "d-none"}>
                <ScaleLoader color={color} size={50} />
            </div>
            <Header />
            <div className="CntLdDv">
                <div className="container">
                    <div className="PrvPlyPg">
                        <div className="PrvPlyTp">
                            <h3>{privacytitle}</h3>
                            <h4>Last updated: {privacytime}</h4>
                        </div>
                        <div className="PrvPlyCnt" dangerouslySetInnerHTML={{ __html: cmsdata?.content }}>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export default Privacy;