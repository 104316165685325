import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CustomizeProvider } from './common/Context';
import { SocketContext, socket } from "./socket/index";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SocketContext.Provider value={socket}>

      <CustomizeProvider>

        <App />
      </CustomizeProvider>
    </SocketContext.Provider>


  </React.StrictMode>
);


reportWebVitals();
